"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TraceService = void 0;
var config_1 = require("../config");
var TraceStatus = {
    ERROR: "ERROR",
    INFO: "INFO",
    SUCCESS: "SUCCESS",
};
var TraceService = /** @class */ (function () {
    function TraceService(kongService, kafkaRestUrl, traceContext) {
        this.kongService = kongService;
        this.kafkaRestUrl = kafkaRestUrl;
        this.traceContext = traceContext;
    }
    TraceService.createTraceId = function () {
        return config_1.default.createTraceId();
    };
    TraceService.createTraceContext = function (channel, operation, company, clientId, advisorId) {
        return {
            advisorId: advisorId,
            channel: channel,
            clientId: clientId,
            company: company,
            operation: operation,
            traceId: this.createTraceId(),
        };
    };
    TraceService.prototype.info = function (step, message, detail) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.trace(TraceStatus.INFO, step, message, detail)];
            });
        });
    };
    TraceService.prototype.error = function (step, message, detail) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.trace(TraceStatus.ERROR, step, message, detail)];
            });
        });
    };
    TraceService.prototype.success = function (step, message, detail) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.trace(TraceStatus.SUCCESS, step, message, detail)];
            });
        });
    };
    TraceService.prototype.span = function (step, message, detail) {
        var _this = this;
        var info = this.info(step, message, detail);
        var startTime = new Date().getTime();
        var elapsedTimeInMs = function () { return new Date().getTime() - startTime; };
        return {
            error: function (estep, emessage, edetail) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, info];
                        case 1:
                            _a.sent();
                            return [2 /*return*/, this.error(estep, "Failed in ".concat(elapsedTimeInMs(), " ms: ").concat(emessage), edetail)];
                    }
                });
            }); },
            success: function (sstep, smessage, sdetail) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, info];
                        case 1:
                            _a.sent();
                            return [2 /*return*/, this.success(sstep, "Completed in ".concat(elapsedTimeInMs(), " ms: ").concat(smessage), sdetail)];
                    }
                });
            }); },
        };
    };
    TraceService.prototype.trace = function (status, step, message, detail) {
        return __awaiter(this, void 0, void 0, function () {
            var kongToken, headers, now, date, time, fixedTraceContext, traceMark;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.kongService.getToken()];
                    case 1:
                        kongToken = _a.sent();
                        headers = {
                            Authorization: "Bearer ".concat(kongToken),
                            "Content-Type": "application/vnd.kafka.json.v2+json",
                        };
                        now = new Date();
                        date = "".concat(("0" + now.getDate()).slice(-2), "/").concat(("0" + (now.getMonth() + 1)).slice(-2), "/").concat(now.getFullYear());
                        time = "".concat(("0" + now.getHours()).slice(-2), ":").concat(("0" + now.getMinutes()).slice(-2), ":").concat(("0" + now.getSeconds()).slice(-2));
                        fixedTraceContext = __assign(__assign({}, this.traceContext), { advisorId: this.traceContext.advisorId || "", clientId: this.traceContext.clientId || "" });
                        traceMark = __assign(__assign({}, fixedTraceContext), { detail: detail, message: message, status: status, step: step, time: "".concat(date, " ").concat(time) });
                        return [4 /*yield*/, fetch(this.kafkaRestUrl, {
                                method: "POST",
                                headers: headers,
                                body: JSON.stringify({ records: [{ value: traceMark }] })
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return TraceService;
}());
exports.TraceService = TraceService;
