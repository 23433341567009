"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FulfillmentService = exports.PFG_TRACE_ID_HEADER = void 0;
exports.PFG_TRACE_ID_HEADER = "PFG-Trace-ID";
var FulfillmentService = /** @class */ (function () {
    function FulfillmentService(kongService, traceService) {
        this.kongService = kongService;
        this.traceService = traceService;
        this.traceMessageMaxLen = 1000;
    }
    FulfillmentService.prototype.getHeaders = function (channelHeader) {
        return __awaiter(this, void 0, void 0, function () {
            var kongToken;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.kongService.getToken()];
                    case 1:
                        kongToken = _c.sent();
                        if (channelHeader) {
                            return [2 /*return*/, (_a = {
                                        Authorization: "Bearer ".concat(kongToken)
                                    },
                                    _a[exports.PFG_TRACE_ID_HEADER] = this.traceService.traceContext.traceId,
                                    _a["X-CHANNEL-KEY"] = channelHeader,
                                    _a["Content-Type"] = "application/json",
                                    _a)];
                        }
                        else {
                            return [2 /*return*/, (_b = {
                                        Authorization: "Bearer ".concat(kongToken)
                                    },
                                    _b[exports.PFG_TRACE_ID_HEADER] = this.traceService.traceContext.traceId,
                                    _b["Content-Type"] = "application/json",
                                    _b)];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    FulfillmentService.prototype.postWithTrace = function (url, payload, step, channelHeader) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getHeaders(channelHeader)];
                    case 1:
                        headers = _a.sent();
                        return [2 /*return*/, this.withTrace(step, "POST to ".concat(url), function () { return __awaiter(_this, void 0, void 0, function () {
                                var result;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, fetch(url, {
                                                method: "POST",
                                                headers: headers,
                                                body: JSON.stringify(payload)
                                            })];
                                        case 1:
                                            result = _a.sent();
                                            return [2 /*return*/, this.handleResponse(result)];
                                    }
                                });
                            }); })];
                }
            });
        });
    };
    FulfillmentService.prototype.putWithTrace = function (url, payload, step) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getHeaders()];
                    case 1:
                        headers = _a.sent();
                        return [2 /*return*/, this.withTrace(step, "PUT to ".concat(url), function () { return __awaiter(_this, void 0, void 0, function () {
                                var result;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, fetch(url, {
                                                method: "PUT",
                                                headers: headers,
                                                body: JSON.stringify(payload)
                                            })];
                                        case 1:
                                            result = _a.sent();
                                            return [2 /*return*/, this.handleResponse(result)];
                                    }
                                });
                            }); })];
                }
            });
        });
    };
    FulfillmentService.prototype.patchWithTrace = function (url, payload, step) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getHeaders()];
                    case 1:
                        headers = _a.sent();
                        return [2 /*return*/, this.withTrace(step, "PATCH to ".concat(url), function () { return __awaiter(_this, void 0, void 0, function () {
                                var result;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, fetch(url, {
                                                method: "PATCH",
                                                headers: headers,
                                                body: JSON.stringify(payload)
                                            })];
                                        case 1:
                                            result = _a.sent();
                                            return [2 /*return*/, this.handleResponse(result)];
                                    }
                                });
                            }); })];
                }
            });
        });
    };
    FulfillmentService.prototype.getWithTrace = function (url, step) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getHeaders()];
                    case 1:
                        headers = _a.sent();
                        return [2 /*return*/, this.withTrace(step, "GET to ".concat(url), function () { return __awaiter(_this, void 0, void 0, function () {
                                var result;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, fetch(url, {
                                                method: "GET",
                                                headers: headers,
                                            })];
                                        case 1:
                                            result = _a.sent();
                                            return [2 /*return*/, this.handleResponse(result)];
                                    }
                                });
                            }); })];
                }
            });
        });
    };
    FulfillmentService.prototype.deleteWithTrace = function (url, step) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getHeaders()];
                    case 1:
                        headers = _a.sent();
                        return [2 /*return*/, this.withTrace(step, "DELETE to ".concat(url), function () { return __awaiter(_this, void 0, void 0, function () {
                                var result;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, fetch(url, {
                                                method: "DELETE",
                                                headers: headers,
                                            })];
                                        case 1:
                                            result = _a.sent();
                                            return [2 /*return*/, this.handleResponse(result)];
                                    }
                                });
                            }); })];
                }
            });
        });
    };
    FulfillmentService.prototype.withTrace = function (step, description, promiseToTrace) {
        return __awaiter(this, void 0, void 0, function () {
            var operation, span;
            var _this = this;
            return __generator(this, function (_a) {
                operation = this.constructor.name;
                span = this.traceService.span(operation, step, description);
                return [2 /*return*/, promiseToTrace()
                        .then(function (result) {
                        span.success(operation, step, _this.shortenedTraceMessage(JSON.stringify(result)));
                        return result;
                    })
                        .catch(function (reason) {
                        span.error(operation, step, _this.shortenedTraceMessage(JSON.stringify(reason)));
                        throw reason;
                    })];
            });
        });
    };
    FulfillmentService.prototype.shortenedTraceMessage = function (message) {
        if (message.length > this.traceMessageMaxLen) {
            var diff = this.traceMessageMaxLen - message.length;
            return "".concat(message.slice(0, this.traceMessageMaxLen), "... (and ").concat(diff, " chars more)");
        }
        return message;
    };
    FulfillmentService.prototype.handleResponse = function (response) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, response.json()];
            });
        });
    };
    return FulfillmentService;
}());
exports.FulfillmentService = FulfillmentService;
